// Custom javascript

/**
 * Initialize AOS
 */
if (AOS) {
    AOS.init({
        startEvent: 'DOMContentLoaded',
        duration: 800,
        once: true,
        easing: 'cubic-bezier(.645,.045,.355,1)',
    });
}

jQuery(document).ready(function($) {
    var siteHeader  = $('#navigation');
    var headerHeight = 0;
    function checkHeaderScroll() {
        var scrollPosition = $(window).scrollTop();
        if (scrollPosition > headerHeight) {
            siteHeader.addClass('scrolled');
        } else {
            siteHeader.removeClass('scrolled');
        }
    }
    $(window).load(function() {
        checkHeaderScroll();
    });
    $(window).on('scroll', function(){
        checkHeaderScroll();
    });
   
  $(".menu-toggle").on('click', function(e) {
    $(".hamburger").toggleClass("active");
    $(".close").toggleClass("active");
    $(".site-menu").toggleClass("open");
    $("body").toggleClass("modal-open");
    e.preventDefault();
  });

});

// jQuery(window).resize(function () {
    
// var viewportWidth = $(window).width();

// if (viewportWidth > 991) {
//     $("#page-nav .collapse").removeClass("show");
//     $("#content").removeClass("nav-open");
// }
// }); 

jQuery(document).ready(function($) {

    $("#page-nav .nav-toggle").on('click', function(e) {
      $("#content").toggleClass("nav-open");
    });
    $(".nav-tabs").on('click', function(e) {
        $("#page-nav .collapse").removeClass("show");
        $("#content").removeClass("nav-open");
    });
    $(".section-xlarge").on('click', function(e) {
        $("#page-nav .collapse").removeClass("show");
        $("#content").removeClass("nav-open");
    });


});


// function MenuListener() {

//     const viewportWidth = $(window).width();
//     const siteMenu      = document.getElementById("leytbild-model");
//     const menuToggle    = document.getElementById("leytbild-model");

//     if (viewportWidth > 1280) {
//         siteMenu.remove("open");
//         menuToggle.remove("active");
//     }
// };
// window.addEventListener("resize", MenuListener);


/**
 * Fancybox
 */
jQuery(document).ready(function($) {
    //var tabContainer = document.getElementById("tab-content");
    $(".topics").fancybox({
        beforeShow: function(){
         // tarGet= this.href;
        },
        afterClose: function(){
         $(this.href).show();
        },
    });
});

/**
 * Scroll to top of bootstrap tab container
 * @source https://stackoverflow.com/a/61843356
 * @source https://www.sitepoint.com/community/t/scroll-down-to-to-id-with-javascript-fixed/423158/2
 * @source https://www.w3schools.com/jsref/met_document_queryselectorall.asp
 */
function scrollToTabContainerTop() {
    
    var tabContainer = document.getElementById("tab-content");
    
    if ( tabContainer ) {
        var tabContainerTop = tabContainer.offsetTop;
        window.scrollTo({
            top: tabContainerTop,
            behavior: "smooth"
        });
    
    };

}

var tabLinks = document.querySelectorAll('.tab-link');
for (let i = 0; i < tabLinks.length; i++) {

    tabLinks[i].addEventListener('click', _ => {
        scrollToTabContainerTop();
    });
};

/**
 * Open bootstrap tabs on page refresh or with external link
 * 
 * @source https://github.com/understrap/understrap/issues/1583
 * @source https://www.viperswebdesign.com/blog/how-to-add-deep-linking-to-the-bootstrap-5-tabs-component
 */

// Import Tab module from bootstrap js.
import Tab from 'bootstrap/js/src/tab';

// Load bootstrap tab on page refresh.
let url = location.href.replace(/\/$/, '');
// Only execute if window hash was found.
if (location.hash) {
    // Set variables.
    const hash = url.split('#');
    const currentTab = document.querySelector('#nav-tab .tab-link[data-bs-target="#' + hash[1] + '"]');
    const curTab = Tab.getOrCreateInstance(currentTab);
    curTab.show();
    //url = location.href.replace(/\/#/, '#');
    //history.replaceState(null, null, url);
    scrollToTabContainerTop();
}

// Change url based on selected bootstrap tab.
// const selectableTabList = [].slice.call(document.querySelectorAll('.tab-link[data-bs-toggle="tab"]'));
// selectableTabList.forEach((selectableTab) => {
//   //const selTab = Tab.getOrCreateInstance(selectableTab)
//   selectableTab.addEventListener('click', function () {
//     var newUrl;
//     const hash = selectableTab.getAttribute('data-bs-target');
//     if (hash) {
//       newUrl = url.split('#')[0] + hash;
//     }
//     history.replaceState(null, null, newUrl);
//   });
// });

// Change url based on selected bootstrap tab.
const remoteTabLinkList = [].slice.call(document.querySelectorAll('.tab-link-remote'));
remoteTabLinkList.forEach((remoteTabLink) => {
    remoteTabLink.addEventListener('click', function () {
        const targetHash = remoteTabLink.getAttribute('data-bs-remote-target');
        if (targetHash) {
            const selectedTab = document.querySelector('#nav-tab .tab-link[data-bs-target="' + targetHash + '"]');
            const selTab = Tab.getOrCreateInstance(selectedTab);
            selTab.show();
            scrollToTabContainerTop();
        }
    });
});


/**
 * Customer area
 */
jQuery(document).ready(function($) {

    var customerArea = document.getElementById("customer-area");
    
    if ( customerArea ) {

        $(document).on('click', '.open-mail-form', function () {
            var dl_ID = $(this).data('id');
            $(".modal-body #download_id").val( dl_ID );
        });
        $('.openPopup').on('click',function(){
            $("#customer-area").addClass("wait");
            var dataURL = $(this).attr('data-href');
            $('.modal-content').load(dataURL,function(){
                $('#content-modal').modal({show:true});
            });
            $('#content-modal').on('shown.bs.modal', function () {
            $("#customer-area").removeClass("wait");
            });
        }); 

    }; 

}); 

/**
 * Modal autoplay.
 */

// jQuery(document).ready(function($) {
//     /* Get iframe src attribute value i.e. YouTube video url
//     and store it in a variable */
//     var modal           = document.getElementById('#modal-video');
    
//     if (modal) {
//         var url_um          = '//www.youtube.com/embed/kQ61Pn5QLwI?autoplay=1';
//         var url_um_en       = '//www.youtube.com/embed/9NCa_Y7EMrQ?autoplay=1';
//         var url_app         = '//www.youtube.com/embed/7IFE9HPjL_Q?autoplay=1';
//         /* Assign the initially stored url back to the iframe src
//         attribute when modal is displayed */
//         $("#modal-video").on('shown.bs.modal', function(){
//             $("#video-unternehmen").attr('src', url_um);
//         });
//         $("#modal-video-en").on('shown.bs.modal', function(){
//             $("#video-unternehmen").attr('src', url_um_en);
//         });
//         $("#modal-video-app").on('shown.bs.modal', function(){
//             $("#video-app").attr('src', url_app);
//         });
        
//         /* Assign empty url value to the iframe src attribute when
//         modal hide, which stop the video playing */
//         $("#modal-video").on('hide.bs.modal', function(){
//             $("#video-unternehmen").attr('src', '');
//         });
//         $("#modal-video-en").on('hide.bs.modal', function(){
//             $("#video-unternehmen").attr('src', '');
//         });
//         $("#modal-video-app").on('hide.bs.modal', function(){
//             $("#video-app").attr('src', '');
//         });
//     };
// });